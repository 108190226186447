import SYSTEM_CONSTANTS from '../common/constants';
import {AttributeData, Icon, IconAttributes, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface Video {
  Description: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  Title: string;
  id?: number;
  Video?: {
    data: IconAttributes;
  }
}


export default class VideosAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Video> | null> {
      let api = `${VideosAPI.host}/${SYSTEM_CONSTANTS.Video.GET}?populate=Video`;
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Video>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
  static getVideo(id: number): Observable<ResponseData<Video> | null> {
    let api = `${VideosAPI.host}/${SYSTEM_CONSTANTS.Video.GET}?populate=Videos&filters[id][$eq]=${id}`;
    return HttpClient.get(api, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
    }).pipe(
        map(
            res => (res as ResponseData<Video>) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
}
}
