import React, { useEffect, useState } from 'react';
import {Image, Modal} from 'antd';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import CompanyFooter from '../../footer/CompanyFooter';
import SYSTEM_CONSTANTS from '../../../common/constants';
import Banner from '../PortraitGallery/Banner/Banner';
import { useSelectorRoot } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { getVideos } from '../../../store/controls/Video.slice';
import { Video } from '../../../api/Video.api';
import { format } from 'date-fns';

function Videos() {
  const location = useLocation();
  const dispatch = useDispatch();
  const {VideosData} = useSelectorRoot(state => state.video)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [videoData, setVideoData] = useState(VideosData?.data)
  useEffect(() => {
    setVideoData(VideosData?.data)
  },[VideosData]);
  useEffect(() => {
    dispatch(getVideos())
  }, [])
  const showModal = (item: Video) => {
    console.log(item);
    let videoUrl =  SYSTEM_CONSTANTS.HOST + item.Video?.data.attributes.url
    setCurrentVideoUrl(videoUrl);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCurrentVideoUrl('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentVideoUrl('');
  };

  return (
    <div>
      <Banner title={"Video"}></Banner>
      
      
      <div className="media-section">
        <h3>Video</h3>
        <div className="media-grid">
          {videoData?.map((item, index) => (
            <div className="media-item" key={index} onClick={() => showModal(item.attributes)}>
                <div className="player-wrapper">
                 <div className="player-overlay" />
                <ReactPlayer url={SYSTEM_CONSTANTS.HOST + item.attributes?.Video?.data.attributes.url} playing={false} controls={false} pip={false} light={true} style={{width: '400px', height: '200px'}} width="100%"
        height="100%"></ReactPlayer>
                </div>
              <div className="media-title">{item.attributes?.Title}</div>
              <div className="media-date">{format(new Date(item.attributes?.publishedAt), 'yyyy-MM-dd HH:mm:ss')}</div>
            </div>
          ))}
        </div>
      </div>
      <CompanyFooter />
      <Modal
        title="Video"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <ReactPlayer
          url={currentVideoUrl}
          controls
          width="100%"
          height="300px"
        />
      </Modal>
    </div>
  );
}

export default Videos;