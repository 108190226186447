import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { set } from "date-fns";
import ConfigsAPI, { Config } from "../../api/Config.api";

interface ConfigState {
    loading: boolean;
    ConfigData: ResponseData<Config> | null
    errorMessage: string | null;
    isShowHome: boolean;
}

const initState: ConfigState = {
    loading: false,
    ConfigData: null,
    errorMessage: null,
    isShowHome: true,
};
const ConfigSlice = createSlice({
    name: 'Config',
    initialState: initState,
    reducers: {
        getConfig(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getConfigSuccess(state, action: PayloadAction<ResponseData<Config> | null>) {
            state.loading = false;
            state.ConfigData = action.payload;
        },
        setIsShowHome(state, action: PayloadAction<boolean>) {
            state.isShowHome = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Config$: RootEpic = (action$) =>
    action$.pipe(
        filter(getConfig.match),
        switchMap((re) => {
            return ConfigsAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        ConfigSlice.actions.getConfigSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ConfigSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ConfigEpics = [Config$];
export const {
    getConfig,
    getConfigSuccess,
    setIsShowHome
} = ConfigSlice.actions;
export const ConfigReducer = ConfigSlice.reducer;