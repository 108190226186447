import {combineReducers} from '@reduxjs/toolkit';
import {combineEpics} from 'redux-observable';
import { ProductionsEpics, ProductionsReducer } from './controls/Productions.slice';
import { config } from 'process';
import { TagEpics, TagReducer } from './controls/Tag.slice';
import { CategorysEpics, CategorysReducer } from './controls/Categorys.slice';
import { ChildsEpics, ChildsReducer } from './controls/Child.slice';
import { PostsEpics, PostsReducer } from './controls/Post.slice';
import { BestPostEpics, BestPostReducer } from './controls/BestPost.slice';
import { ConfigEpics, ConfigReducer } from './controls/Config.slice';
import { HomeDataEpics, HomeDataReducer } from './controls/HomeData';
import { VideosEpics, VideosReducer } from './controls/Video.slice';

const rootReducer = combineReducers({
    productions: ProductionsReducer,
    Tag: TagReducer,
    categorys: CategorysReducer,
    Child: ChildsReducer,
    post: PostsReducer,
    bestPost: BestPostReducer,
    config: ConfigReducer,
    homeData: HomeDataReducer,
    video: VideosReducer
});

export const rootEpic = combineEpics(
    ...ProductionsEpics,
    ...TagEpics,
    ...CategorysEpics,
    ...ChildsEpics,
    ...PostsEpics,
    ...BestPostEpics,
    ...ConfigEpics,
    ...HomeDataEpics,
    ...VideosEpics
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
