import React, { useEffect, useState } from 'react';
import Banner from '../PortraitGallery/Banner/Banner';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Drawer, Layout, Menu, Row, Typography} from 'antd';
import CompanyFooter from '../../footer/CompanyFooter';
import { useDispatchRoot, useSelectorRoot } from '../../../store/store';
import { getTag } from '../../../store/controls/Tag.slice';
import { getPosts } from '../../../store/controls/Post.slice';
import SYSTEM_CONSTANTS from '../../../common/constants';
import { FilterOutlined } from '@ant-design/icons';
const { Header, Content, Sider } = Layout;
const {Title, Text} = Typography;

const Activities = () => {
  const location = useLocation(); 
  const dispath = useDispatchRoot();
  const [selectedTime, setSelectedTime] = useState('all')
  const history = useHistory();
  const {TagData} = useSelectorRoot(state => state.Tag);
  const {PostsData} = useSelectorRoot(state => state.post);
  const [selectedTag, setSelectedTag] = useState('all');
  
  const [posts, setPosts] = useState(PostsData?.data.map(item => ({
    id: item.id,
    title: item.attributes.Title,
    image: item.attributes.Banner?.data?.attributes.url,
    time: item.attributes.publishedAt,
    category: item.attributes.category?.data?.attributes.Title,
    createBy: item.attributes.CreatedBy ?? 'Quản trị viên',
    Content: item.attributes.Content,
    Body: item.attributes.Body,
  })));
  const [tag, setTag] = useState(TagData?.data.map(item => ({
    value: item.attributes.Code,
    name: item.attributes.Name,
    selected: false
  }))??[]);
  
  const getTitle = () => {
    const path = location.pathname;
    // Match the current path to the correct Menu key
    if (path.includes('/kienthuc')) return 'KIẾN THỨC';
    if (path.includes('/chiase')) return 'CHIA SẺ';
    if (path.includes('/hoatdong')) return 'HOẠT ĐỘNG';
    return ''; // Default case
  };
  const [filter, setFilter] = useState({
    danhmuc: {
      category: 'Danh mục',
      filter: [...(tag ?? []), {
        value: 'all',
        name: 'Tất cả',
        selected: true
      }]
    },
    time: {
      category: 'Thời gian',
      filter: [
        {
          id: 'all',
          name: 'Tất cả',
          value: 'all',
          selected: true
        }
      ]
    }
  });
  const getData = () => {
    const path = location.pathname;
    console.log(selectedTag);
    setPosts(PostsData?.data.filter(x => 
      path.includes(x.attributes.category?.data?.attributes?.Router ?? 'all')
      && (x.attributes.publishedAt.slice(0, 10) === selectedTime || selectedTime === 'all')
      && (x.attributes.tag?.data?.attributes?.Code == selectedTag || selectedTag === 'all')
    ).map(item => ({
      id: item.id,
      title: item.attributes.Title,
      image: item.attributes.Banner?.data?.attributes.url,
      time: item.attributes.publishedAt,
      category: item.attributes.tag?.data?.attributes?.Name ?? '',
      createBy: item.attributes.CreatedBy ?? 'Quản trị viên',
      Content: item.attributes.Content,
      Body: item.attributes.Body,
    })));
  }
  useEffect(() => {
    
    getData();
  }, [PostsData, selectedTag, selectedTime]);
  useEffect(() => {
    const path = location.pathname;
    let tagFilter = TagData?.data.filter(x => path.includes(x.attributes.category?.data.attributes?.Router ?? 'all')).map(item => ({
      id: item.id,
      name: item.attributes.Name,
      value: item.attributes.Code,
      selected: false,
    })) ?? [];
    console.log(TagData);
    setTag(tagFilter);
    setFilter({
      ...filter,
      danhmuc: {
        category: 'Danh mục',
        filter: [...(tagFilter ?? []), {
          value: 'all',
          name: 'Tất cả',
          selected: true
        }]
      },
    });
  }, [TagData]);
  useEffect(() => {
    const path = location.pathname;
    let filData = {
      danhmuc: {
        category: 'Danh mục',
        filter: [...(tag ?? []), {
          value: 'all',
          name: 'Tất cả',
          selected: true
        }]
      },
      time: {
        category: 'Thời gian',
        filter: [
          {
            id: 'all',
            name: 'Tất cả',
            value: 'all',
            selected: true
          }
        ]
      }
    }
    const now = new Date(); // Lấy ngày hiện tại
    // Lặp qua 12 tháng từ thời điểm hiện tại
    for (let i = 0; i < 6; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() + i, 1); // Tháng mới
      filData.time.filter.push({
        name: `${date.getMonth() + 1}/${date.getFullYear()}`,  // Tên của tháng
        value: new Date(date.getFullYear(), date.getMonth(), 1).toISOString(),
        selected: false,
        id: `Value ${i + 1}`
      });
    }
    setFilter(filData);
    dispath(getTag());
    dispath(getPosts({
      category: path.split('/').pop() ?? ''
    }));
  }, [location]);
  const [drawerVisible, setDrawerVisible] = useState(false); // Drawer state
  const renderFilters = () => (
    <div style={{ width: '100%', backgroundColor: 'white', height: '100%', padding: 20 }}>
      <Title level={4}>{'Danh Mục'}</Title>
      {filter.danhmuc.filter?.map((x) => (
        <Button
          key={x.value}
          type={x.value === selectedTag ? 'default' : 'text'}
          style={{ width: '100%', margin: 0, justifyContent: 'flex-start', padding: '20px 10px' }}
          onClick={() => setSelectedTag(x.value)}
        >
          {x.name}
        </Button>
      ))}
      <Title level={4}>{'Thời Gian'}</Title>
      {filter.time.filter.map((x) => (
        <Button
          key={x.value}
          type={x.value === selectedTime ? 'default' : 'text'}
          style={{ width: '100%', margin: 0, justifyContent: 'flex-start', padding: '20px 10px' }}
          onClick={() => setSelectedTime(x.value)}
        >
          {x.name}
        </Button>
      ))}
    </div>
  );
  return (
    <div>
      <Banner title={getTitle()} />
      <Layout>
        {window.innerWidth > 600 ? (
          <Sider width={200} style={{ backgroundColor: '#f0f2f5' }}>
            {renderFilters()}
          </Sider>
        ) : (
          <>
            <Button
              style={{ marginBottom: 16, marginLeft: 16 }}
              onClick={() => setDrawerVisible(true)}
              icon={<FilterOutlined />}
            >
            </Button>
            <Drawer
              title="Filters"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
            >
              {renderFilters()}
            </Drawer>
          </>
        )}
        <Layout style={{ padding: '24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            <Row gutter={[24, 24]} style={{ padding: 8 }}>
              {posts?.map((article, index) => (
                <Col md={16} key={index} xs={24} sm={24} lg={8}>
                  <Card
                    className="small-news"
                    hoverable
                    cover={<img alt="news" src={SYSTEM_CONSTANTS.HOST + article.image} className="small-news-image" />}
                    actions={[
                      <Text style={{ flex: 1 }} type="secondary">
                        {article.time}
                      </Text>,
                      <Button
                        style={{ width: '100%' }}
                        type="text"
                        onClick={() => {
                          let prod = PostsData?.data.find((x) => x.id === article.id);
                          history.push(
                            '/news/' + prod?.attributes.category?.data?.attributes.Router + '/' + article.id
                          );
                        }}
                      >
                        {'Đọc tiếp'}
                      </Button>,
                    ]}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ backgroundColor: '#228B22', padding: 10, color: 'white' }}>
                        {article.category}
                      </div>
                    </div>
                    <Title style={{ flex: 1 }} level={5}>
                      {article.title}
                    </Title>
                    <Text style={{ flex: 1 }} type="secondary">
                      Đăng bởi: {article.createBy}
                    </Text>
                    <p>{article.Content}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Content>
        </Layout>
      </Layout>
      <CompanyFooter />
    </div>
  );
};

export default Activities;