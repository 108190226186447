import { FloatButton } from "antd";
import { useEffect, useState } from "react";
import SYSTEM_CONSTANTS from "../../common/constants";
import { useSelectorRoot } from "../../store/store";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { getConfig } from "../../store/controls/Config.slice";
import facebookIcon from "../../image/icon/facebook.png";
import zaloIcon from "../../image/icon/zalo.png";
import youtubeIcon from "../../image/icon/youtube.jpg";
import phoneIcon from "../../image/icon/phone.png";
import emailIcon from "../../image/icon/email.png";

export const FloatContact = () => {
    const dispatch = useDispatch();
    const { ConfigData } = useSelectorRoot(state => state.config);
    useEffect(() => {
        dispatch(getConfig());
    }, []);
    console.log(ConfigData);
    const renderItem = (item: { id: number, attributes: any }) => (
        <FloatButton key={item.id}
            onClick={() => {
                console.log(item.attributes);
                window.open(`${item.attributes.Value}`, '_blank');
            }}
            icon={
                <img
                    alt={item?.attributes.Name ?? ''}
                    // src={`${SYSTEM_CONSTANTS.HOST}${item?.attributes.Image?.data[0]?.attributes.url}`}
                    src={item?.attributes.url}
                    style={{ width: 20, height: 20, objectFit: 'cover', }} />} />
    )
    return (
        <FloatButton.Group shape="circle" style={{ right: 20 }}
            // trigger={withScreen && withScreen < 500 ? "click" : undefined}
            icon={<QuestionCircleOutlined style={{ backgroundColor: '#00B4D8', color: 'white', borderRadius: '50%' }} />}
        >
            {
                renderItem({ id: 1, attributes: {
                    name: 'Facebook',
                    url: facebookIcon,
                    Value: ConfigData?.data[0]?.attributes?.facebook,
                } })
            }
            {
                renderItem({ id: 1, attributes: {
                    name: 'youtube',
                    url: youtubeIcon,
                    Value: ConfigData?.data[0]?.attributes?.youtube,
                } })
            }
            {
                renderItem({ id: 1, attributes: {
                    name: 'email',
                    url: emailIcon,
                    Value: 'mailto:'+ ConfigData?.data[0]?.attributes?.email,
                } })
            }
            {
                renderItem({ id: 1, attributes: {
                    name: 'phone',
                    url: phoneIcon,
                    Value: 'tel:' + ConfigData?.data[0]?.attributes?.phone,
                } })
            }
        </FloatButton.Group>
    )
}