import React, { useEffect, useState } from 'react';
import { Layout, Menu, Card, Row, Col, Typography, Avatar, Space } from 'antd';
import { EyeOutlined, CommentOutlined } from '@ant-design/icons';
import Banner from '../../PortraitGallery/Banner/Banner';
import image181 from '../../../../image/hoatdong/image181.png';
import image183 from '../../../../image/kienthuc/image183.png';
import image176 from '../../../../image/hoatdong/image176.png';
import image178 from '../../../../image/hoatdong/image178.png';
import CompanyFooter from '../../../footer/CompanyFooter';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '../../../../store/store';
import { getPost, getPosts } from '../../../../store/controls/Post.slice';
import SYSTEM_CONSTANTS from '../../../../common/constants';
import { set } from 'date-fns';
import Utils from '../../../../common/Utils';
const { Header, Content, Sider } = Layout;
const { Title, Paragraph } = Typography;

// const relatedArticles = [
//   {
//     title: 'Tiêu đề bài viết',
//     content: 'Lorem ipsum dolor sit amet consectetur...',
//     time: '01/08/2024',
//     image: image183
//   },
//   {
//     title: 'Tiêu đề bài viết',
//     content: 'Lorem ipsum dolor sit amet consectetur...',
//     time: '01/08/2024',
//     image: image178
//   },
//   {
//     title: 'Tiêu đề bài viết',
//     content: 'Lorem ipsum dolor sit amet consectetur...',
//     time: '01/08/2024',
//     image: image176
//   },
// ];

const NewsDetail = () => {
  const locastion = useLocation();
  const history = useHistory();
  const id = locastion.pathname.split('/')[3];
  const dispatch = useDispatchRoot();
  const {newData, PostsData} = useSelectorRoot(state => state.post);
  const [news, setNews] = useState(newData)
  const [relatedArticles, setRelatedArticles] = useState(PostsData?.data ?? []);
  useEffect(() => {
    if (newData) {
      let data = { ...newData };
      
      if (data?.Images?.data && newData?.Images?.data && newData.Images?.data?.length > 0 && newData.Body) {
        for (let i = 0; i < newData?.Images?.data?.length; i++) {
          console.log(data.Images?.data[i]);
          if (newData?.Images?.data[i].attributes.url.includes('http')) {
            data.Body = data.Body.replace(`{${i}}`, newData.Images.data[i].attributes.url);
          } else {
            data.Body = data.Body.replace(`{${i}}`, `<img src="${SYSTEM_CONSTANTS.HOST + newData.Images.data[i].attributes.url}" alt="Image" style="object-fit: cover; width: 100%;">`)
          }
        }
       
      }
      dispatch(getPosts({category: data.category?.data?.attributes.Router, tag: data.tag?.data?.attributes?.Code}));
      setNews(data);
    }
    
  }, [newData])
  useEffect(() => {
    setRelatedArticles(PostsData?.data.filter(x => x.id != Number(id)) ?? []);
  }, [PostsData])
  useEffect(() => {
    
    console.log(locastion.pathname.split('/')[3]);
    dispatch(getPost({id}))
    
  }, [locastion])
  return (
    <div>
    <Banner title={news?.Title ?? ''} />
      <Layout style={{ padding: '24px' }}>
        <Content style={{ width: '75%' }}>
          <Card
            cover={
              <img
                alt="example"
                src={SYSTEM_CONSTANTS.HOST + news?.Banner?.data?.attributes.url}
              />
            }
            style={{ marginBottom: '20px' }}
          >
            <Title level={2} style={{textAlign: 'center'}}>{news?.Title}</Title>
            <Space style={{textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center'}}>
              <span>Đăng bởi: {news?.CreatedBy}</span>
              <span>{news?.publishedAt? Utils.formatDate(new Date(news?.publishedAt)) : ""}</span>
              <Space>
                <EyeOutlined /> Lượt xem: {news?.Viewer}
              </Space>
            </Space>
            
            {/* Sử dụng dangerouslySetInnerHTML để load HTML từ body */}
            <div dangerouslySetInnerHTML={{ __html: news?.Body ?? '' }} />
          </Card>
        </Content>

        <Sider width={300} style={{ background: '#fff', padding: '24px' }}>
          <Title level={4}>Bài viết khác</Title>
          {relatedArticles.map((article, index) => (
            <Card
              key={index}
              style={{ marginBottom: '20px' }}
              cover={<img alt="related post" src={ SYSTEM_CONSTANTS.HOST + article.attributes.Banner?.data?.attributes.url} />}
              onClick={() => {
                history.push('/news/' + article?.attributes.category?.data?.attributes.Router + '/'+ article.id)
              }}
            >
              <Title level={5}>{article.attributes.Title}</Title>
              <Paragraph>{article.attributes.Content}</Paragraph>
              <Paragraph>{article.attributes.publishedAt}</Paragraph>
            </Card>
          ))}
        </Sider>
      </Layout>
      <CompanyFooter></CompanyFooter>
      </div>
  );
};

export default NewsDetail;
