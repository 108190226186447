import React, { useEffect, useState } from 'react';
import './home.css';
import { Row, Col, Card, Button, Typography } from 'antd';
import testImage from '../../../image/imageTest.png';
import goalBG from '../../../image/goal_bg.png';
import value_image from '../../../image/value_image.png';
import fa_solid_hands from '../../../image/icon/fa-solid_hands.svg';
import fluent_people from '../../../image/icon/fluent_people-team-24-filled.svg';
import game_icon from '../../../image/icon/game-icons_fist.svg';
import mask_group from '../../../image/icon/mask-group.svg';
import mdi_arm from '../../../image/icon/mdi_arm-flex.svg';
import mdi_love from '../../../image/icon/mdi_love.svg';
import CompanyFooter from '../../footer/CompanyFooter';
import LatestNews from './latestNews/latestNews';
import anhbia from '../../../image/anhbia.png';
import trungtam from '../../../image/trungtam.png';
import thongtintrungtam from '../../../image/thongtintrungtam.png';
import { useHistory } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '../../../store/store';
import { get } from 'http';
import { getBestPost } from '../../../store/controls/BestPost.slice';
import SYSTEM_CONSTANTS from '../../../common/constants';
import ConfigsAPI from '../../../api/Config.api';
import { getConfig } from '../../../store/controls/Config.slice';
import { FloatContact } from '../../footer/FloatContact';
import { getHomeData } from '../../../store/controls/HomeData';

const { Title, Text } = Typography;

const Home = () => {
    const history = useHistory();
    const dispath = useDispatchRoot();
    const {BestPostData} = useSelectorRoot(state => state.bestPost);
    const {HomeDataData} = useSelectorRoot(state => state.homeData);
    const [goalData, setGoalData] = useState(HomeDataData?.data?.filter(item => item.attributes.type === 'goal').map(item => item.attributes));
    const [dataForD, setdataForD] = useState(HomeDataData?.data?.filter(item => item.attributes.type === 'train').map(item => item.attributes));
    const [coreValues, setCoreValues] = useState(HomeDataData?.data?.filter(item => item.attributes.type === 'core_values').map(item => item.attributes));
    useEffect(() => {
        setGoalData(HomeDataData?.data?.filter(item => item.attributes.type === 'goal').map(item => item.attributes));
        setdataForD(HomeDataData?.data?.filter(item => item.attributes.type === 'train').map(item => item.attributes));
        setCoreValues(HomeDataData?.data?.filter(item => item.attributes.type === 'core_values').map(item => item.attributes));
    }, [HomeDataData])
    // const goalData = 
    // [
    //     {
    //         title: 'Hòa nhập',
    //         description: 'Giúp các em hòa nhập với cộng đồng, phát triển tự tin và kỹ năng sống.',
    //     },
    //     {
    //         title: 'Nhân tài',
    //         description: 'Phát hiện và phát triển tài năng riêng của các em, khuyến khích và tạo điều kiện tốt nhất.',
    //     },
    //     {
    //         title: 'Hướng nghiệp',
    //         description: 'Định hướng và hỗ trợ các em trong việc học tập và phát triển nghề nghiệp trong tương lai.'
    //     }
    // ]
    const coreImages = [
        {
            title: 'Thần tượng',
            description: 'Chúng tôi tạo ra những hình mẫu tích cực để các em noi theo',
            image: mask_group,
            typeImage: 'thantuong'
        },
        {
            title: 'Yêu thương',
            description: 'Tình yêu thương và sự quan tâm luôn là nền tảng trong mọi hoạt động của chúng tôi',
            image: mdi_love,
            typeImage: 'yeuthuong'
        },
        {
            title: 'Kỷ cương',
            description: 'Duy trì kỷ luật và trách nhiệm trong học tập và sinh hoạt',
            image: game_icon,
            typeImage: 'kycuong'
        },
        {
            title: 'Đồng đội',
            description: 'Khuyến khích tinh thần đồng đội và hỗ trợ lẫn nhau',
            image: fluent_people,
            typeImage: 'dongdoi'
        },
        {
            title: 'Kiên gan',
            description: 'Giúp các em rèn luyện tính kiên nhẫn và kiên trì vượt qua khó khăn',
            image: mdi_arm,
            typeImage: 'kiengan'
        },
        {
            title: 'Phụng sự',
            description: 'Tận tâm phục vụ vì sự phát triển và hạnh phúc của các em',
            image: fa_solid_hands,
            typeImage: 'phungsu'
        }
    ]
    // const dataForD = [
    //     {
    //         title: '1D',
    //         text1: 'Trên xuống',
    //         text2: 'Không thầy đố mày làm nên',
    //     },
    //     {
    //         title: '2D',
    //         text1: 'Bằng hữu',
    //         text2: 'Học thầy không tày học bạn',
    //     },
    //     {
    //         title: '3D',
    //         text1: 'Dưới lên',
    //         text2: 'Con hơn cha là nhà có phúc',
    //     },
    //     {
    //         title: '4D',
    //         text1: 'Tự thân',
    //         text2: 'Tự huấn luyện - Đỉnh cao huấn luyện',
    //     },
    // ]
    const [width, setWidth] = useState(window.innerWidth);
    const [first, setFiirst] = useState(BestPostData?.data?.find(x => x.attributes?.category?.data?.attributes?.Code == SYSTEM_CONSTANTS.hoatdong)?.attributes?.posts?.data?.[0]);
    const [sukien, setSukien] = useState(BestPostData?.data?.find(x => x.attributes?.category?.data?.attributes?.Code == SYSTEM_CONSTANTS.hoatdong)?.attributes?.posts?.data?.slice(1) ?? []);
    useEffect(() => {
        setFiirst(BestPostData?.data?.find(x => x.attributes?.category?.data?.attributes?.Code == SYSTEM_CONSTANTS.hoatdong)?.attributes?.posts?.data?.[0]);
        setSukien(BestPostData?.data?.find(x => x.attributes?.category?.data?.attributes?.Code == SYSTEM_CONSTANTS.hoatdong)?.attributes?.posts?.data?.slice(1) ?? []);
        console.log(BestPostData?.data?.find(x => x.attributes?.category?.data?.attributes?.Code == SYSTEM_CONSTANTS.hoatdong)?.attributes?.posts?.data?.[0])
    }, [BestPostData]);
    useEffect(() => {
        dispath(getBestPost());
        console.log("width screen",width);
        dispath(getConfig());
        dispath(getHomeData());
        const handleResize = () => {
            setWidth(window.innerWidth);
            console.log("width screen",width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="app">
            {/* Main Content */}
            <Row gutter={[16, 16]} style={{ padding: '20px' }}>
                {/* Left Content */}
                <Col xs={24} md={16}>
                    <Card
                        hoverable
                        styles={{
                            body: {
                                padding: '0px',
                            }
                        }}
                        onClick={() => {
                            history.push('/news/'+first?.attributes?.category?.data?.attributes.Router + '/' +first?.id);
                        }}
                        style={{height: width > 500? 700 : 250, marginBottom: 20}}
                    >
                        <img alt="Event" src={SYSTEM_CONSTANTS.HOST+first?.attributes?.Banner?.data?.attributes?.url} style={{ width: '100%', height:  width > 500? 700 : 250, objectFit: 'fill'}} />
                        {/* Overlay Text */}
                        <div className="overlay">
                        <div className="category-tag" style={{fontSize: width > 500? 20 : 14}}>{first?.attributes?.category?.data?.attributes.Title}</div>
                        <div>
                        <Text style={{ color: '#fff', fontSize: width > 500? 18 : 12 }}>{first?.attributes?.publishedAt}</Text>
                        <Title level={3} style={{ color: '#fff', fontSize: width > 500? 18 : 16 }}>{first?.attributes?.Title}</Title>
                        </div>
                        
                            
                        </div>

                    </Card>
                </Col>

                {/* Right Content */}
                <Col xs={24} md={8}>
                {
                    sukien.slice(0, 2).map((item, index) => (
                        
                    <Card
                        hoverable
                        styles={{
                            body: {
                                padding: '0px',
                            }
                        }}
                        onClick={() => {
                            history.push('/news/'+item?.attributes?.category?.data?.attributes.Router + '/' +item.id);
                        }}
                        style={{height:  width > 500? 350 : 250, marginBottom: 20}}
                    >
                         <img alt="Event" src={SYSTEM_CONSTANTS.HOST+item.attributes?.Banner?.data?.attributes?.url} style={{ width: '100%', height:  width > 500? 350 : 250, maxHeight: 500 }} />
                        {/* Overlay Text */}
                        <div className="overlay">
                        <div className="category-tag" style={{fontSize: width > 500? 20 : 14}}>{item.attributes?.category?.data?.attributes.Title}</div>
                        <div>
                        <Text style={{ color: '#fff', fontSize: width > 500? 18 : 12 }}>{item.attributes?.publishedAt}</Text>
                        <Title level={3} style={{ color: '#fff', fontSize: width > 500? 18 : 16 }}>{item.attributes?.Title}</Title>
                        </div>
                        </div>

                    </Card>
                ))
                }
                </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Col xs={24} md={16}>
                    <Title style={{ color: "#237F0A",  fontSize: width > 500? 30 : 24 }} level={1}>Nâng cao sức mạnh cho trẻ em có khiếm khuyết qua giáo dục và hỗ trợ</Title>
                    <Text style={{ fontSize: width > 500? 24 : 16 }}>
                        Tại trung tâm của chúng tôi, các em nhỏ được học tập, phát triển và tỏa sáng trong một môi trường đầy yêu thương và chăm sóc. Đội ngũ chuyên gia tận tâm luôn đồng hành và giúp đỡ để mỗi em có thể phát huy hết tiềm năng của mình.
                    </Text>
                    <Row style={{ marginTop: 16, marginBottom: 10 }}>
                        <Button type="primary" style={{ marginRight: 10, backgroundColor: "#237F0A", fontSize: width > 500? 24 : 12, padding: width > 500? 30:5  }}
                        onClick={() => { window.location.href = "tel:0939337568" }}
                        >Liên hệ</Button>
                        <Button style={{ fontSize: width > 500? 24 : 12, padding: width > 500? 30:5 }}>Chung tay</Button>
                    </Row>
                </Col>
                <Col xs={24} md={8}>
                    <img alt="Luôn dõi theo con" src={testImage} style={{ width: '100%', height: 'auto', maxHeight: 500 }} />
                </Col>
            </Row>
            {/* Goals Section */}
            <Row className="goals" justify="center" style={{ backgroundImage: "url(" + goalBG + ")", backgroundSize: 'fill', backgroundPosition: 'center', padding: '40px 0' }}>
                <Col span={24}>
                    <Title level={2} style={{ textAlign: 'center', color: '#22a722', fontSize: width > 500? 30 : 24, }}>Định hướng của chúng tôi</Title>
                </Col>
                {goalData?.map((goal, index) => (

                    <Col xs={24} md={8}>
                        <div style={{
                            textAlign: 'center',
                            borderRadius: 200,
                            backgroundColor: '#fff',
                            padding: '20px',
                            width: width > 500? 300 : 200,
                            height:  width > 500? 250 : 200,
                            margin: '0 auto',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            marginBottom: 20,
                        }}>
                            <div style={{ width: 200 }}>
                                <Title level={1} style={{fontSize: width > 500? 30 : 24}}>{goal.title}</Title>
                                <Text style={{ fontSize: width > 500? 20 : 16 }}>{goal.description}</Text>

                            </div>

                        </div>
                    </Col>
                ))}
            </Row>
            {/* Grid for 1D to 4D */}
            <Row gutter={[16, 16]} justify="center" style={{ backgroundImage: "url(" + goalBG + ")", backgroundSize: 'cover', backgroundPosition: 'center', justifyContent: 'center', alignItems: 'center', paddingBottom: 40 }}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center', fontSize: 20 }}>Phương pháp giảng dạy</Text>
                </Col>
                <Col span={24}>
                    <Title level={2} style={{ textAlign: 'center', color: '#22a722', fontSize: width > 500? 30 : 24 }}>Kiến tạo cuộc sống mới với các chương trình giáo dục đặc biệt</Title>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '20px', fontSize: 20 }}>Huấn luyện 4D dịch chuyển đẳng cấp!</Text>
                </Col>
                {
                    dataForD?.map((item, index) => (
                        <Col xs={24} sm={12} md={6} style={{justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Card hoverable style={{ height: 190, maxWidth: 300, minWidth: 250 }}>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Title level={1} style={{ textAlign: 'center', fontSize: 24 }}>{item.title}</Title>
                                    <Title level={4} style={{ color: '#22a722', textAlign: 'center', fontSize: 24 }}>{item.description}</Title>
                                </Row>

                                <Text style={{ textAlign: 'center', fontSize: 20 }}>{item.subtext}</Text>
                            </Card>
                        </Col>
                    ))
                }


            </Row>


            {/* Core Values Section */}
            <div style={{ backgroundImage: "url(" + value_image + ")", backgroundSize: 'fill', backgroundPosition: 'center' }}>
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: '40px' }}>
                    <Title level={2} style={{ textAlign: 'center', color: '#fff' }}>Giá trị cốt lõi</Title>
                    <Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
                        {
                            coreValues?.map((value, index) => (
                                <>
                                {
                                    coreImages.find(x => x.typeImage == value.icon) &&
                                    <Col xs={24} sm={8} md={4}>
                                    <div className="value-card">
                                        <img src={coreImages.find(x => x.typeImage == value.icon)?.image} alt={value.title} className="icon" />
                                        <Title level={2} style={{ textAlign: 'center', color: '#fff' }}>{value.title}</Title>
                                        <Text style={{ textAlign: 'center', color: '#fff', fontSize: 20 }}>{value.description}</Text>
                                    </div>
                                </Col>
                                }
                                </>
                                
                            ))
                        }

                    </Row>
                </div>

            </div>
            <LatestNews></LatestNews>
            <CompanyFooter></CompanyFooter>
            <FloatContact></FloatContact>
        </div>
    );
};
export default Home;


