import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import VideosAPI, { Video } from "../../api/Video.api";

interface VideosState {
    loading: boolean;
    VideosData: ResponseData<Video> | null
    Video: Video | null;
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
}

const initState: VideosState = {
    loading: false,
    Video: null,
    VideosData: null,
    errorMessage: null,
    pagination: null
};
const VideosSlice = createSlice({
    name: 'Videos',
    initialState: initState,
    reducers: {
        getVideos(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getVideosSuccess(state, action: PayloadAction<ResponseData<Video> | null>) {
            state.loading = false;
            state.VideosData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        getVideo(state, action: PayloadAction<number>) {
            state.loading = true;
        },
        setVideo(state, action: PayloadAction<Video | null>) {
            state.loading = false;
            state.Video = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Videos$: RootEpic = (action$) =>
    action$.pipe(
        filter(getVideos.match),
        switchMap((re) => {
            return VideosAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        VideosSlice.actions.getVideosSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    VideosSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const Videoren$: RootEpic = (action$) =>
    action$.pipe(
        filter(getVideo.match),
        switchMap((re) => {
            return VideosAPI.getVideo(re.payload).pipe(
                mergeMap((res) => {
                    return [
                        VideosSlice.actions.setVideo(res?.data[0].attributes ?? null),
                    ];
                }),
                catchError((e: AjaxError) => [
                    VideosSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const VideosEpics = [Videos$, Videoren$];
export const {
    getVideos,
    getVideosSuccess,
    getVideo,
    setVideo,
} = VideosSlice.actions;
export const VideosReducer = VideosSlice.reducer;