import SYSTEM_CONSTANTS from '../common/constants';
import { Icon, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface Config {
  Title: string;
  email: string;
  phone: string;
  id: number;
  zalo: string;
  address: string;
  youtube: number;
  Status: number;
  facebook: string;
  cost: any;
  dinhhuong:any;
  giangday:any;
}
export default class ConfigsAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Config> | null> {
      const api = `${ConfigsAPI.host}/${SYSTEM_CONSTANTS.Config.GET}?populate=Icon`;
      
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Config>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
