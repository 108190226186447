import SYSTEM_CONSTANTS from '../common/constants';
import { Icon, IconAttributes, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface HomeData {
  title: string;
  description: string;
  subtext: string;
  image: {data?: IconAttributes};
  id: number;
  type: string;
  icon: string;
}
export default class HomeDatasAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<HomeData> | null> {
      const api = `${HomeDatasAPI.host}/${SYSTEM_CONSTANTS.HomeData.GET}?populate=Icon`;
      
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<HomeData>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
