import React, { useEffect, useState } from 'react';
import { Layout, Typography, Image, Row, Col, Button, Input } from 'antd';
import { PhoneOutlined, EnvironmentOutlined, FacebookOutlined, MailOutlined} from '@ant-design/icons';
import './CompanyFooter.css';
import bocongthuong from '../../image/icon/bo-cong-thuong.png';
import { useSelectorRoot } from '../../store/store';
import { useDispatch } from 'react-redux';
import logo from '../../image/logo.png';
import footer from '../../image/footer.png';

const { Footer } = Layout;
const { Text, Title, Link } = Typography;

const CompanyFooter = () => {
  const {ConfigData} = useSelectorRoot(state => state.config);
  console.log(ConfigData);
  return (
    <div>
      <div  style={{ width: '100%', backgroundImage: 'url('+footer+')',  height: 120}} />
      <div className="footer">
      <Row justify="space-between" align="middle" style={{padding: '0px 40px'}}>
        <Col xs={24} sm={12} className="footer-contact">
        <Row>
        <img src={logo} alt="Hoa Xuyên Chi Logo" className="logo" />
        <div style={{flex: 1, marginLeft: 10}}>
        <Text strong style={{ color: 'white', fontSize: 20 }}>{ConfigData?.data[0].attributes.Title}</Text>
          <p style={{ color: 'white', fontSize: 20 }}>
            <MailOutlined />{ConfigData?.data[0].attributes.email}<br />
            <PhoneOutlined /> {ConfigData?.data[0].attributes.phone}<br />
            <EnvironmentOutlined /> {ConfigData?.data[0].attributes.address}
          </p>
        </div>
        </Row>
          
        </Col>
        <Col xs={24} sm={6} >
          <Row gutter={16} style={{flex: 1, margin: 0, padding: 0}}>
            <Col xl={12} sm={16}>
            <Input placeholder="Địa chỉ email của bạn"  style={{marginBottom: 10}}/>
            </Col>
            <Col xl={4} sm={16}>
            <Button type="primary" style={{marginLeft: 10, marginTop:0,  backgroundColor: "white", color: "#228B22", fontSize: 16}}>Kết nối với chúng tôi</Button>
            </Col>
            </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" className="footer-bottom">
        <Col>
          <Text style={{ color: 'white' }}>
            Copyright © 2024 Hoa Xuyên Chi. All Rights Reserved.
          </Text>
        </Col>
        <Col>
          <Link href={ConfigData?.data[0].attributes.facebook} target="_blank" className="social-icon">
            <FacebookOutlined style={{ fontSize: '24px', marginLeft: '10px' }} />
          </Link>
        </Col>
      </Row>
    </div>
    </div>
   
  );
};

export default CompanyFooter;
