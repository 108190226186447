import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import HomeDatasAPI, { HomeData } from "../../api/HomeData.api";

interface HomeDataState {
    loading: boolean;
    HomeDataData: ResponseData<HomeData> | null
    errorMessage: string | null;
    isShowHome: boolean;
}

const initState: HomeDataState = {
    loading: false,
    HomeDataData: null,
    errorMessage: null,
    isShowHome: true,
};
const HomeDataSlice = createSlice({
    name: 'HomeData',
    initialState: initState,
    reducers: {
        getHomeData(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getHomeDataSuccess(state, action: PayloadAction<ResponseData<HomeData> | null>) {
            state.loading = false;
            state.HomeDataData = action.payload;
        },
        setIsShowHome(state, action: PayloadAction<boolean>) {
            state.isShowHome = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const HomeData$: RootEpic = (action$) =>
    action$.pipe(
        filter(getHomeData.match),
        switchMap((re) => {
            return HomeDatasAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        HomeDataSlice.actions.getHomeDataSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    HomeDataSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const HomeDataEpics = [HomeData$];
export const {
    getHomeData,
    getHomeDataSuccess,
    setIsShowHome
} = HomeDataSlice.actions;
export const HomeDataReducer = HomeDataSlice.reducer;